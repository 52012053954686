export function loginReducer(state, action) {
  switch (action.type) {
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "login": {
      return {
        ...state,
        error: "",
        isLoading: true,
        isFocused: true,
      };
    }
    case "success": {
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        error: "",
        user: action.payload,
      };
    }
    case "error": {
      return {
        ...state,
        error: action.payload, // "Nieprawidłowy login lub hasło",
        isLoggedIn: false,
        isLoading: false,
        isFocused: true,
      };
    }
    case "logout": {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: "",
      };
    }
    default:
      return state;
  }
}
