import React, { useContext } from "react";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import "./Header.scss";
import { Context } from "../../context";
import { logOut } from "components/Utils/utils";

function Header(props) {
  const { state, dispatch } = useContext(Context);
  const { user } = state;

  return (
    <>
      <div className="logo" onClick={props.goBack} style={{ cursor: "pointer" }}>
        <img src={logo} alt="logo" />
      </div>

      <div className="log-outcont">
        <p>
          Zalogowano jako: {user.login} ({user.project})
        </p>

        <Link to="/">
          <div className="log-out">
            <button
              className="btn-out btn-template "
              type="button"
              onClick={() => {
                logOut(dispatch);
              }}
            >
              WYLOGUJ SIĘ
            </button>
          </div>
        </Link>
      </div>
      <p className="paragraph-header">
        Zalogowano jako: {user.login} ({user.project})
      </p>
    </>
  );
}

export default Header;
