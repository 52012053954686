import React, { useEffect, useRef,  useContext } from "react";

import { verifyLogin } from "../Utils/utils";

import logo from "../../assets/logo.svg";
import "./Login.scss";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";

import { Context } from "context";

export default function LoginHooks() {
  const { state, dispatch } = useContext(Context);
  // const [state, dispatch] = useReducer(loginReducer, initialState);

  // console.log(state);

  const { isLoading, isLoggedIn, error, isFocused } = state;
  const usernameRef = useRef(null);
  //const { setUserState, setPassState } = useContext(Context);

  useEffect(() => {
    if (isFocused) {
      usernameRef.current.focus();
    }
  }, [isFocused]);

  let history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/ankiety");
    }
  }, [history, isLoggedIn]);

  /*useEffect(() => {
    setUserState(username);
    setPassState(password);
  }, [password, setPassState, setUserState, username]);*/

  //console.log(isLoading);

  return (
    <div className="login" style={{ left: isLoading && "-200px", opacity: isLoading && 0, transition: isLoading && "0.5s" }}>
      <div>
        <img className="logo" src={logo} alt="logo" />
      </div>
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={(values) => {
          verifyLogin({ username: values.username, password: values.password }, dispatch);
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => (
          <Form className="formik-cont">
            {
              <p className="error" style={{ backgroundColor: error ? "block" : "hidden" }}>
                {error}{" "}
              </p>
            }
            <TextField
              className="input-login"
              id="outlined-basic"
              label="Login"
              variant="outlined"
              type="text"
              ref={usernameRef}
              placeholder=""
              autoFocus
              name="username"
              onChange={handleChange}
              value={values.username}
            />
            <TextField className="input-login" id="outlined-basic2" label="Hasło" variant="outlined" type="password" placeholder="" name="password" onChange={handleChange} value={values.password} />

            <button className=" submit inp  btn-template " onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? "LOGUJĘ....." : "ZALOGUJ SIĘ"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
