import React, { useState, useCallback, useEffect } from "react";
import "./Ankieta.scss";

import { useHistory, useParams } from "react-router-dom";

//import { Context } from "context";

import { saveSurvey, saveStatus } from "components/Utils/utils";

import Header from "components/Header/Header";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import toast, { Toaster } from "react-hot-toast";

const odpowiedzi = [
  {
    id: 1,
    odp: "zdecydowanie nie",
    value: 1,
  },
  {
    id: 2,
    odp: "nie",
    value: 2,
  },
  {
    id: 3,
    odp: "raczej nie",
    value: 3,
  },
  {
    id: 4,
    odp: "raczej tak",
    value: 4,
  },
  {
    id: 5,
    odp: "tak",
    value: 5,
  },
  {
    id: 6,
    odp: "zdecydowanie tak",
    value: 6,
  },
];

function Ankieta(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [stimulateHook, setStimulateHook] = useState(0);

  const [dataSurvey, setDataSurvey] = useState([]);
  const [dataResult, setDataResult] = useState([]);
  const [dataStatus, setDataStatus] = useState(0);
  const [finalResult, setFinalResult] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [whichGroup, setWhichGroup] = useState([]);

  const { pesel, pomiarTyp } = useParams();

  const isLetter = (c) => {
    return c.toLowerCase() !== c.toUpperCase();
  };

  const notify = () => toast("Odpowiedź została zapisana");

  useEffect(() => {
    console.log(pomiarTyp);
    fetch((isLetter(pesel[0]) ? `/api/surveys/passport/` + pesel : `/api/surveys/pesel/` + pesel) + (pomiarTyp ? "/" + pomiarTyp : ""))
      .then((response) => response.json())
      .then((data) => {
        setDataSurvey(data.data);
        setDataStatus(parseInt(data.data.status));
        setDataResult(data.data.result);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [pesel, stimulateHook, pomiarTyp]);

  useEffect(() => {
    if (dataSurvey.groups !== undefined) {
      // eslint-disable-next-line array-callback-return
      setIsCompleted(true);
      dataSurvey.groups.map((grupa) => {
        if (grupa.sum === grupa.questions.length) {
        } else setIsCompleted(false);
        return true;
      });
      return isCompleted;
    }
  }, [dataSurvey, isCompleted]);

  useEffect(() => {
    if (dataResult) {
      let arrayValue = Object.values(dataResult).map((el) => el.avg);
      let arrayLength = Object.values(dataResult).map((el) => el.avg).length;
      let arrayReduce = arrayValue.reduce((a, b) => a + b, 0);

      setFinalResult(arrayReduce / arrayLength);
    }
  }, [dataResult]);

  // console.log(isCompleted)

  function handleChange(evt, group_index, question_index, question_id) {
    saveSurvey(
      {
        id: dataSurvey["id"],
        pesel: dataSurvey["pesel"],
        password: dataSurvey["password"],
        question_id,
        value: parseInt(evt.target.value),
      },
      pomiarTyp
    );

    dataSurvey["groups"][group_index]["questions"][question_index]["value"] = parseInt(evt.target.value);

    forceUpdate();
    notify();
    const timer = setTimeout(() => {
      setStimulateHook(stimulateHook + 1);
    }, 100);
    return () => clearTimeout(timer);
  }

  function handleStatusChange() {
    let newSurvey = saveStatus(
      {
        id: dataSurvey["id"],
        pesel: dataSurvey["pesel"],
      },
      pomiarTyp
    );
    newSurvey.then((data) => {
      setDataResult(data.survey !== undefined && Object.values(data.survey)[1]);
      setDataStatus(data.survey !== undefined && Object.values(data.survey)[0]);
    });
  }

  let history = useHistory();

  const acordionCart = (el) => {
    if (!whichGroup.find(({ id }) => id === el.id)) setWhichGroup([...whichGroup, el.id]);
  };

  const removeAcordionCart = (el) => {
    let hardCopy = [...whichGroup];
    hardCopy = hardCopy.filter((cartItem) => cartItem !== el.id);
    setWhichGroup(hardCopy);
  };

  return (
    <>
      <div className="App-header">
        <Header goBack={() => history.push("/ankiety")} />
      </div>
      <div className="ankieta-cont">
        {/* --------------------------------------------------Survey----------------------------------------------------------- */}
        <div className="dalej-powrot">
          <div className="arrow-cont powrot" onClick={() => history.push("/ankiety" + (pomiarTyp ? "/" + pomiarTyp : ""))}>
            <div className="cross"></div>
            <p className="arrow-para">
              <i className="arrow left"></i>powrót
            </p>
          </div>
        </div>
        {dataStatus === 0 && (
          <div className="ankieta">
            <h2>{dataSurvey.name}</h2>
            <h3>
              ({pomiarTyp ? "" : "Pomiar bazowy"}
              {pomiarTyp === "p2" ? "Pomiar częściowy: sierpień 2021" : ""}
              {pomiarTyp === "p3" ? "Pomiar częściowy: sierpień 2022" : ""}
              {pomiarTyp === "p4" ? "Pomiar częściowy: sierpień 2023" : ""}
              {pomiarTyp === "p5" ? "Pomiar końcowy" : ""})
            </h3>
            <p>
              Ankieta utworzona przez {dataSurvey.user_organization} - {dataSurvey.user_email}
            </p>
            {dataSurvey.pesel !== undefined && dataSurvey.pesel !== "" && <h4>{`pesel: ${dataSurvey.pesel}`}</h4>}
            {dataSurvey.passport !== undefined && dataSurvey.passport !== "" && <h4>{`paszport: ${dataSurvey.passport}`}</h4>}
            {dataSurvey.groups &&
              dataSurvey.groups.map((grupa, index_group) => {
                return (
                  <div key={index_group} className="survey-cont">
                    <Accordion expanded={whichGroup.find((el) => el === grupa.id) ? true : false}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={(e) => {
                          if (whichGroup.find((el) => el === grupa.id)) {
                            removeAcordionCart(grupa);
                          } else {
                            acordionCart(grupa);
                          }
                        }}
                      >
                        <span className="grupa-name">{grupa.name} </span>
                        <span className="grupa-sum">
                          {grupa.sum} / {grupa.questions.length}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        {grupa.questions.map((pytanie, index_pytanie) => (
                          <div key={pytanie.id}>
                            <p className="pytanie">{`${pytanie.order}. ${pytanie.name}`}</p>
                            <div className="radio-cont">
                              <FormControl component="fieldset" className="radio">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                  className="radioMaterial"
                                  aria-label={pytanie.id.toString()}
                                  name={`pytanie` + pytanie.id}
                                  value={parseInt(pytanie.value)}
                                  onChange={(e) => handleChange(e, index_group, index_pytanie, pytanie.id)}
                                >
                                  {odpowiedzi.map((odp) => {
                                    return <FormControlLabel key={odp.id} name={pytanie.id.toString()} value={odp.value} control={<Radio />} label={odp.odp} />;
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <hr></hr>
                          </div>
                        ))}
                        <div
                          className="arrow-zwin"
                          onClick={() => {
                            removeAcordionCart(grupa);
                          }}
                        >
                          <i className="arrow up"></i>
                          <span>zwiń</span>{" "}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            {dataSurvey.length !== 0 && isCompleted && (
              <div>
                <button onClick={() => handleStatusChange()} className="btn-template">
                  ZAPISZ
                </button>
              </div>
            )}
            <Toaster
              toastOptions={{
                // Define default options
                className: "",
                style: {
                  margin: "20px",
                  background: "white",
                  color: "black",
                  border: "2px solid black",
                  zIndex: 1,
                  transition: "0.5s ease",
                },
                duration: 1800,
                // Default options for specific types
              }}
            />
          </div>
        )}
        {dataStatus === 1 && (
          <div className="survey-cont">
            <h2>{dataSurvey.name}</h2>
            <h3>
              ({pomiarTyp ? "" : "Pomiar bazowy"}
              {pomiarTyp === "p2" ? "Pomiar częściowy: sierpień 2021" : ""}
              {pomiarTyp === "p3" ? "Pomiar częściowy: sierpień 2022" : ""}
              {pomiarTyp === "p4" ? "Pomiar częściowy: sierpień 2023" : ""}
              {pomiarTyp === "p5" ? "Pomiar końcowy" : ""})
            </h3>
            <p>
              Ankieta utworzona przez {dataSurvey.user_organization} - {dataSurvey.user_email}
            </p>
            {dataSurvey.pesel !== undefined && dataSurvey.pesel !== "" && <h4>{`pesel: ${dataSurvey.pesel}`}</h4>}
            {dataSurvey.passport !== undefined && dataSurvey.passport !== "" && <h4>{`paszport: ${dataSurvey.passport}`}</h4>}
            {dataSurvey.groups.map((grupa, index_group) => (
              <div className="wynik" key={index_group}>
                <span className="name">{grupa.name}</span>
                <span className="note">wynik: {dataResult[grupa.id] && dataResult[grupa.id].avg}</span>
              </div>
            ))}
            <h3>Wynik końcowy: {finalResult.toFixed(2)} </h3>
          </div>
        )}
      </div>
    </>
  );
}

export default Ankieta;
