import axios from "axios";

export async function verifyLogin({ username, password }, dispatch) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("login", username);
    formData.append("password", password);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return axios
      .post("/api/users/authenticate", formData, config)
      .then((response) => {
        if (response.data.error) {
          dispatch({ type: "error", payload: response.data.error });
        } else {
          if (response.data.user && response.data.user.id) {
            dispatch({ type: "success", payload: response.data.user });
          } else {
            dispatch({ type: "error", payload: "Błąd!" });
          }
        }
      })
      .catch((err) => {
        dispatch({ type: "error", payload: err.message });
      });
  });
}

export async function logOut(dispatch) {
  return axios
    .get("/api/users/logout")
    .then((response) => {
      if (response.data.error) {
        dispatch({ type: "error", payload: response.data.error });
      } else {
        dispatch({ type: "logout" });
      }
    })
    .catch((err) => {
      dispatch({ type: "error", payload: err.message });
    });
}

export async function saveSurvey(survey, pomiarTyp = "") {
  return new Promise(() => {
    let formData = new FormData();
    formData.append("survey", JSON.stringify(survey));

    const config = {
      headers: { status: "multipart/form-data" },
    };
    return axios
      .post("/api/surveys/save" + (pomiarTyp ? "/" + pomiarTyp : ""), formData, config)
      .then((response) => {
        if (response.data.error) {
        } else {
          return response.data;
        }
      })
      .catch((err) => {});
  });
}

export async function saveStatus(survey, pomiarTyp = "") {
  let formData = new FormData();

  formData.append("survey", JSON.stringify(survey));

  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return axios
    .post("/api/surveys/status" + (pomiarTyp ? "/" + pomiarTyp : ""), formData, config)
    .then((response) => {
      if (response.data.error) {
      } else {
        return response.data;
      }
    })
    .catch((err) => {});
}
