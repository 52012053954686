import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Context } from "context";

import GuardedRoute from "./components/GuardedRoute/GuardedRoute";

import LoginHooks from "./components/Login/LoginHooks";
import Ankiety from "./components/Ankiety/Ankiety";
import Ankieta from "./components/Ankieta/Ankieta";

import { Switch, Route } from "react-router-dom";
import "./App.scss";

function App() {
  const { state, setPageChange } = useContext(Context);
  const { isLoggedIn } = state;

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          setPageChange("ankiety");
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          setPageChange("ankiety");
        }
      }
    });
  }, [locationKeys, history, setPageChange]);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <LoginHooks />
        </Route>
        <GuardedRoute exact path="/ankieta/:pesel" component={Ankieta} auth={isLoggedIn} />
        <GuardedRoute exact path="/ankieta/:pesel/:pomiarTyp" component={Ankieta} auth={isLoggedIn} />
        <GuardedRoute exact path="/ankiety" component={Ankiety} auth={isLoggedIn} />
        <GuardedRoute exact path="/ankiety/:pomiarTyp" component={Ankiety} auth={isLoggedIn} />
      </Switch>
    </div>
  );
}

export default App;
