import React, { useState, useContext, useEffect, useRef } from "react";
import "./Ankiety.scss";
import { DataGrid, plPL } from "@material-ui/data-grid";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "context";

import Header from "components/Header/Header";

import TextField from "@material-ui/core/TextField";
import SearchBar from "material-ui-search-bar";

const theme = createMuiTheme(plPL);

function Ankiety(props) {
  const { pageChange, setPageChange } = useContext(Context);

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [rowSelectedBtn, setRowSelectedBtn] = useState([]);

  const [valuePesel, setValuePesel] = useState("");
  const [valuePass, setValuePass] = useState("");

  const [dodaj, setDodaj] = useState(false);
  const [dodajClick, setDodajClick] = useState(false);

  const [dalej] = useState(false);

  const [searched, setSearched] = useState("");

  let history = useHistory();

  const matches = useMediaQuery("(max-width:700px)");

  const searchBarRef = useRef(null);

  const { pomiarTyp } = useParams();

  const columns = matches
    ? [
        { field: "pesel", flex: 2 },
        {
          field: "passport",
          headerName: "paszport",
          flex: 2,
          renderCell: (e) => <>{e.row.passport}</>,
        },
        {
          field: "status",
          headerName: "status",
          flex: 1.5,
        },

        {
          field: "edytuj",
          flex: 1.5,
          filterable: false,
          renderCell: (e) => (
            <button
              className="button-table"
              onClick={() => {
                setRowSelectedBtn(e.row);
                if (e.row.pesel) {
                  history.push("/ankieta/" + e.row.pesel + (pomiarTyp ? "/" + pomiarTyp : ""));
                } else {
                  if (e.row.passport) {
                    history.push("/ankieta/" + e.row.passport + (pomiarTyp ? "/" + pomiarTyp : ""));
                  }
                }
              }}
            >
              {e.row.status === "0" ? "edytuj" : "zobacz"}
            </button>
          ),
        },
      ]
    : [
        { field: "pesel", flex: 2 },
        {
          field: "passport",
          headerName: "paszport",
          flex: 2,
          renderCell: (e) => <>{e.row.passport}</>,
          disableColumnSelector: true,
        },
        {
          field: "user",
          headerName: "dodano przez",
          flex: 1.5,
          renderCell: (e) => <>{e.row.user}</>,
        },
        {
          field: "date_added",
          headerName: "data dodania",
          flex: 1.5,
          renderCell: (e) => <>{e.row.date_added}</>,
        },
        {
          field: "date_modified",
          headerName: "ostatnia modyfikacja",
          flex: 1.5,
          renderCell: (e) => <>{e.row.date_modified}</>,
        },
        {
          field: "surveyName",
          headerName: "ankieta",
          flex: 1.5,
        },
        {
          field: "progress",
          sortComparator: (v1, v2, param1, param2) => param1.row.progressValue - param2.row.progressValue,
          headerName: "postęp",
          flex: 1.5,
          renderCell: (e) => <>{e.row.progress}</>,
        },
        {
          field: "status",
          headerName: "status",
          flex: 1.5,
        },
        {
          field: "edytuj",
          headerName: "edytuj",
          flex: 1.5,
          renderCell: (e) => (
            <button
              className="button-table"
              onClick={() => {
                setRowSelectedBtn(e.row);
                if (e.row.pesel) {
                  history.push("/ankieta/" + e.row.pesel + (pomiarTyp ? "/" + pomiarTyp : ""));
                } else {
                  if (e.row.passport) {
                    history.push("/ankieta/" + e.row.passport + (pomiarTyp ? "/" + pomiarTyp : ""));
                  }
                }
              }}
            >
              {e.row.status === "w trakcie" ? "edytuj" : "zobacz"}
            </button>
          ),
          filterable: false,
          disableColumnMenu: true,
          sortable: false,
        },
      ];

  useEffect(() => {
    fetch(`/api/surveys/list` + (pomiarTyp ? "/" + pomiarTyp : ""))
      .then((response) => response.json())
      .then((data) => {
        setRows(data.data);
        setFilteredRows(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pomiarTyp]);

  const isLetter = (c) => {
    return c.toLowerCase() !== c.toUpperCase();
  };

  useEffect(() => {
    setDodaj(false);
    setDodajClick(false);

    if (valuePesel !== "" && valuePesel.length === 11) {
      if (typeof valuePesel !== "string") return false;

      let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
      let sum = 0;
      let controlNumber = parseInt(valuePesel.substring(10, 11));

      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(valuePesel.substring(i, i + 1)) * weight[i];
      }
      sum = sum % 10;
      if ((10 - sum) % 10 === controlNumber) {
        setDodaj(true);
      }
    } else {
      if (valuePass !== "" && isLetter(valuePass[0]) && valuePass.length <= 13) {
        setDodaj(true);
      }
    }
  }, [valuePass, valuePesel]);

  const requestSearch = (searchedVal) => {
    const localfilteredRows = rows.filter((row) => {
      return (
        searchedVal === "" ||
        row.pesel.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.passport.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.user.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.date_added.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.date_modified.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.surveyName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.progress.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.status.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setFilteredRows(localfilteredRows);
  };

  useEffect(() => {
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [filteredRows, searchBarRef]);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const checkPeselPassport = () => {
    fetch(`/api/surveys/` + (valuePesel ? `pesel/` + valuePesel : `passport/` + valuePass) + (pomiarTyp ? "/" + pomiarTyp : ""))
      .then((response) => response.json())
      .then((data) => {
        if (data.data.pesel || data.data.passport) {
          history.push("/ankieta/" + (valuePesel ? valuePesel : valuePass) + (pomiarTyp ? "/" + pomiarTyp : ""));
        } else {
          if (data.data.canAdd) {
            setDodajClick(true);
            setDodaj(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addSurvey = (suveyType) => {
    fetch((valuePesel ? `/api/surveys/create/pesel/` + valuePesel : `/api/surveys/create/passport/` + valuePass) + `/type/` + suveyType + (pomiarTyp ? "/" + pomiarTyp : ""))
      .then((response) => response.json())
      .then((data) => {
        if (data.data.pesel || data.data.passport) {
          history.push("/ankieta/" + (valuePesel ? valuePesel : valuePass) + (pomiarTyp ? "/" + pomiarTyp : ""));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="App-header">
        <Header goBack={() => setPageChange("ankiety")} />
      </div>
      <div className="ankieta-cont">
        {/* --------------------------------------------------ANKIETY----------------------------------------------------------- */}
        {pageChange === "ankiety" && (
          <>
            <div className="ankieta-header">
              <span> </span>
              <div>
                <button className={`${pomiarTyp ? "" : "ankieta-btn--active"} ankieta-btn btn-template`} onClick={() => history.push("/ankiety")}>
                  Pomiar bazowy
                </button>
                <button className={`${pomiarTyp === "p2" ? "ankieta-btn--active" : ""} ankieta-btn btn-template`} onClick={() => history.push("/ankiety/p2")}>
                  Pomiar częściowy: sierpień 2021
                </button>
                <button className={`${pomiarTyp === "p3" ? "ankieta-btn--active" : ""} ankieta-btn btn-template`} onClick={() => history.push("/ankiety/p3")}>
                  Pomiar częściowy: sierpień 2022
                </button>
                <button className={`${pomiarTyp === "p4" ? "ankieta-btn--active" : ""} ankieta-btn btn-template`} onClick={() => history.push("/ankiety/p4")}>
                  Pomiar częściowy: sierpień 2023
                </button>
                <button className={`${pomiarTyp === "p5" ? "ankieta-btn--active" : ""} ankieta-btn btn-template`} onClick={() => history.push("/ankiety/p5")}>
                  Pomiar końcowy
                </button>
              </div>
            </div>
            <hr />
            <div className="ankieta-header">
              <div>
                <h3>
                  ANKIETY ({pomiarTyp ? "" : "Pomiar bazowy"}
                  {pomiarTyp === "p2" ? "Pomiar częściowy: sierpień 2021" : ""}
                  {pomiarTyp === "p3" ? "Pomiar częściowy: sierpień 2022" : ""}
                  {pomiarTyp === "p4" ? "Pomiar częściowy: sierpień 2023" : ""}
                  {pomiarTyp === "p5" ? "Pomiar końcowy" : ""})
                </h3>
              </div>
              <button className="ankieta-btn btn-template " onClick={() => setPageChange("add")}>
                dodaj ankietę
              </button>
            </div>

            <div>
              <div
                style={{
                  height: 600,
                  width: "100%",
                  margin: "2rem auto",
                  cursor: "pointer",
                }}
              >
                <SearchBar
                  className="searchBar"
                  value={searched}
                  ref={searchBarRef}
                  onChange={(searchVal) => {
                    requestSearch(searchVal);
                  }}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Wyszukaj "
                />
                <ThemeProvider theme={theme}>
                  <DataGrid
                    disableColumnSelector={true}
                    sortingMode="client"
                    sortingOrder={["desc", "asc"]}
                    rows={filteredRows}
                    columns={columns}
                    pageSize={25}
                    state={{
                      keyboard: {
                        cell: null,
                        columnHeader: null,
                        isMultipleKeyPressed: false,
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            </div>
          </>
        )}

        {/* ----------------------------------------------------USER--------------------------------------------------------- */}
        {pageChange === "user" && (
          <>
            <div onClick={() => setPageChange("ankiety")}>BACK</div>
            <div>
              <p>PESEL: {rowSelectedBtn.pesel} </p>
            </div>
          </>
        )}
        {/* --------------------------------------------------ADD----------------------------------------------------------- */}
        {pageChange === "add" && (
          <>
            <div className="dalej-powrot">
              <div className="arrow-cont powrot" onClick={() => setPageChange("ankiety")}>
                <div className="cross"></div>
                <p className="arrow-para">
                  <i className="arrow left"></i>powrót
                </p>
              </div>

              <div className="arrow-cont dalej " style={{ opacity: dalej ? 1 : 0.3, visibility: "hidden" }} onClick={() => dalej && setPageChange("survey")}>
                <div className="cross"></div>
                <p className="arrow-para">
                  dalej<i className="arrow right"></i>
                </p>
              </div>
            </div>

            <div className="numer-cont">
              <div className="pesel">
                <TextField
                  id="outlined-basic"
                  label="Pesel"
                  variant="outlined"
                  onChange={(e) => setValuePesel(e.target.value)}
                  value={valuePesel}
                  disabled={valuePass === "" ? false : true}
                  style={{
                    opacity: valuePass === "" ? 1 : 0.3,
                    transition: "0.5s",
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="lub">lub</div>
              <div className="pass">
                <TextField
                  id="outlined-basic2"
                  label="Paszport"
                  variant="outlined"
                  onChange={(e) => setValuePass(e.target.value)}
                  value={valuePass}
                  disabled={valuePesel === "" ? false : true}
                  style={{
                    opacity: valuePesel === "" ? 1 : 0.3,
                    transition: "0.5s",
                  }}
                  autoComplete="off"
                />
              </div>
            </div>

            <button className="btn-template" style={{ marginTop: "4rem", opacity: dodaj ? 1 : 0.3 }} onClick={() => dodaj && checkPeselPassport()}>
              Dodaj ankietę
            </button>

            {dodajClick && (
              <div className="button-cont">
                <button
                  className="btn-template"
                  onClick={() => {
                    addSurvey(1);
                  }}
                  style={{ width: "10rem", height: "4rem" }}
                >
                  6 - 12
                </button>
                <button
                  className="btn-template"
                  onClick={() => {
                    addSurvey(2);
                  }}
                  style={{ width: "10rem", height: "4rem" }}
                >
                  13 - 18
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Ankiety;
