import React, { createContext, useState, useReducer, useEffect } from "react";

//import pytania_duze from "components/Ankiety/pytania_duze";
import { loginReducer } from "components/Login/loginReducer";

const Context = createContext({});

// const initialSurveyBig = pytania_duze.map((el) => ({
//   [el.id]: "",
// }));

const ContextProvider = ({ children }) => {
  const initialState = {
    user: null,
    isLoading: false,
    isLoggedIn: false,
    error: "",
  };

  const [state, dispatch] = useReducer(loginReducer, initialState);

  const [pageChange, setPageChange] = useState("ankiety");
  const [userState, setUserState] = useState();
  const [passState, setPassState] = useState();

  useEffect(() => {
    fetch(`/api/users/me`)
      .then((response) => response.json())

      .then((data) => {
        if (data.user && data.user.id) {
          dispatch({ type: "success", payload: data.user });
        } else {
          dispatch({ type: "error", payload: "Zaloguj się!" });
        }
      })
      .catch((error) => {
        dispatch({ type: "error", payload: "" });
      });
  }, []);

  // const [dataSurvey6, setDataSurvey6] = useState();
  // const [dataSurvey13, setDataSurvey13] = useState();

  // const howMany = dataSurvey6 !== undefined && dataSurvey6.groups.map((el) => el.questions.length).reduce((a, b) => a + b, 0);
  // const smallArray = [];

  // for (let i = 0; i <= howMany; i++) {
  //   smallArray.push(i);
  // }

  // const initialSurveySmall = smallArray.map((el) => ({
  //   [el]: "",
  // }));

  // const [radioValue13, setRadioValue13] = useState(initialSurveyBig);
  // const [radioValue6, setRadioValue6] = useState(initialSurveySmall);

  // console.log(radioValue6);

  // useEffect(() => {
  //   fetch(`/api/surveys/pesel/123`)
  //     .then((response) => response.json())

  //     .then((data) => setDataSurvey6(data.data))

  //     .catch((error) => setDataSurvey6({ error, isLoading: false }));
  // }, []);

  // useEffect(() => {
  //   fetch(`/api/surveys/2`)
  //     .then((response) => response.json())

  //     .then((data) => setDataSurvey13(data.data))

  //     .catch((error) => setDataSurvey13({ error, isLoading: false }));
  // }, []);

  // console.log(dataSurvey6);

  // console.log(radioValue6);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        pageChange,
        setPageChange,
        userState,
        setUserState,
        passState,
        setPassState,

        // radioValue13,
        // setRadioValue13,
        // radioValue6,
        // setRadioValue6,
        // dataSurvey6,
        // dataSurvey13,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
export { Context };
